import { default as _91token_93pNbHYrgaeZMeta } from "/var/www/sites/auth.ultrafedt.dk/releases/20241213122157/pages/activate/[token].vue?macro=true";
import { default as indexhaECzUWHkTMeta } from "/var/www/sites/auth.ultrafedt.dk/releases/20241213122157/pages/index.vue?macro=true";
import { default as _91token_93CIBmTja6HcMeta } from "/var/www/sites/auth.ultrafedt.dk/releases/20241213122157/pages/password/reset/[token].vue?macro=true";
import { default as indexVkpqN2yl0OMeta } from "/var/www/sites/auth.ultrafedt.dk/releases/20241213122157/pages/password/reset/index.vue?macro=true";
import { default as redirect4yAq8hWK9vMeta } from "/var/www/sites/auth.ultrafedt.dk/releases/20241213122157/pages/redirect.vue?macro=true";
export default [
  {
    name: "activate-token",
    path: "/activate/:token()",
    component: () => import("/var/www/sites/auth.ultrafedt.dk/releases/20241213122157/pages/activate/[token].vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/sites/auth.ultrafedt.dk/releases/20241213122157/pages/index.vue")
  },
  {
    name: "password-reset-token",
    path: "/password/reset/:token()",
    component: () => import("/var/www/sites/auth.ultrafedt.dk/releases/20241213122157/pages/password/reset/[token].vue")
  },
  {
    name: "password-reset",
    path: "/password/reset",
    component: () => import("/var/www/sites/auth.ultrafedt.dk/releases/20241213122157/pages/password/reset/index.vue")
  },
  {
    name: "redirect",
    path: "/redirect",
    component: () => import("/var/www/sites/auth.ultrafedt.dk/releases/20241213122157/pages/redirect.vue")
  }
]